import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ResizeService } from '../../../services/resize/resize.service';

export const MobileGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);
  const resizeService: ResizeService = inject(ResizeService);

  //*allow redirection if screen is not desktop
  if (window.innerWidth < 1280)
    return true;

  //*specific redirection based on url called
  return router.createUrlTree([resizeService.getRedirectUrl(state.url)]);
};
