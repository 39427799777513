import { Injectable } from '@angular/core';
import { GoogleAuth, InitOptions } from '@codetrix-studio/capacitor-google-auth';
import { environment } from '../../environments/environment';
import { User } from '@codetrix-studio/capacitor-google-auth/dist/esm/definitions';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  constructor(private platform: Platform) {}

  initialize() {
    const options: InitOptions = {
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    };

    if (!this.platform.is('ios')) {
      options.clientId = environment.googleAuthClientId;
    }

    GoogleAuth.initialize(options);
  }

  async login(): Promise<User> {
    return await GoogleAuth.signIn();
  }
}
