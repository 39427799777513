import { Routes } from '@angular/router';
import { AuthGuard } from '../../guards/auth/auth.guard';

export const formationRoutes: Routes = [
  {
    path: 'formations',
    canActivate: [AuthGuard],
    children: [
      // path: ''
      // is in tabs.routes.ts
      {
        path: 'my-formations',
        title: 'Mes formations',
        loadComponent: () => import('./my-formations/my-formations.page').then((m) => m.MyFormationsPage),
      },
      {
        path: 'contact-formation',
        title: 'Contact formations',
        loadComponent: () => import('./contact-formation/contact-formation.page').then((m) => m.ContactFormationPage),
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
];
