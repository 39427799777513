<ion-content>
  <div class="flex h-full items-center justify-center">
    <div class="rounded-200 bg-white p-5 text-center">
      @if (progress()() < 100) {
        <ng-container>
          <arc-linear-progress-bar [value]="progress()()" size="medium"></arc-linear-progress-bar>
          <div class="body-regular-1 pt-3 text-brand">Envoi de la photo {{pictureNumber() ? 'n°' + pictureNumber() : ''}} en cours ({{ progress()() }}%)</div>
        </ng-container>
      }

      @if (progress()() >= 100) {
        <div class="flex justify-center">
          <arc-spinner></arc-spinner>
        </div>
        <div class="body-regular-1 mt-2 text-brand">Analyse et compression de l'image</div>
      }
    </div>
  </div>
</ion-content>
