import { effect, Injectable } from '@angular/core';
import { StateStoreService } from '../state-store/state-store.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { AuthenticationService } from 'src/app/pages/authentication/authentication.service';

//todo: change by @types badgesResult when created
export type Badge = { total: number; structure: number }

@Injectable({
  providedIn: 'root'
})
export class BadgeService extends StateStoreService<Badge> {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    super();
    effect(() => {
      //todo: update with socket event
      if (this.authenticationService.hasAccessToken() && !this.state()?.total && this.state()?.total !== 0) {
        this.setBadgesInState().subscribe();
      }
    });
  }

  setBadgesInState() {
    const url = environment.apiUrl + '/badges/myBadges';
    return this.http.get<Badge>(url).pipe(
      map((data) => {
        this.setState(data);
        return data;
      }),
    );
  }

}
