import { Routes } from '@angular/router';
import { TabsPage } from './tabs.page';
import { AuthGuard } from '../guards/auth/auth.guard';

export const tabsRoutes: Routes = [
  {
    path: '',
    component: TabsPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadComponent: () => import('../pages/home/home.page').then((m) => m.HomePage),
        title: 'Accueil',
      },
      {
        path: 'job',
        loadComponent: () => import('../pages/job/job.page').then((m) => m.JobPage),
      },
      {
        path: 'formations',
        title: 'Formations',
        loadComponent: () => import('../pages/formations/home-formations/home-formations.page').then((m) => m.HomeFormationsPage),
      },

      // move to profile.routes.ts when profile will be replaced by forum
      {
        path: 'profile',
        loadComponent: () => import('../pages/profile/profile/profile.page').then((m) => m.ProfilePage),
        title: 'Mon Profil',
      },

      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
    ],
  },
];
