import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StateStoreService } from '../state-store/state-store.service';
import { Platform } from '@ionic/angular';

export interface Device {
  screenType: 'mobile' | 'tablet' | 'desktop';
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ResizeService extends StateStoreService<Device> {
  constructor(
    private router: Router,
    private platform: Platform,
  ) {
    super();
    this.defineScreenType();
    this.platform.resize.subscribe(() => {
      this.defineScreenType();
    });
  }

  getRedirectUrl(url?: string) {
    let redirectUrl = '/home';
    if (url?.split('/')?.[1] === 'profile') {
      redirectUrl = '/profile';
    } else if (url?.split('/')?.[1] === 'practitioner') {
      redirectUrl = `/practitioner/${url?.split('/')?.[2]}`;
    } else if (url?.split('/')?.[1] === 'practitioners') {
      redirectUrl = '/profile';
    } else {
      switch (url) {
        case '/get-started':
          redirectUrl = '/login';
          break;
      }
    }

    return redirectUrl;
  }

  redirect(currentUrl?: string) {
    const url = this.getRedirectUrl(currentUrl || this.router.url);
    this.router.navigate([url]);
  }

  defineScreenType() {
    if (this.platform.width() >= 1280 && this.state().screenType !== 'desktop') {
      this.setState({ screenType: 'desktop', isMobile: false, isTablet: false, isDesktop: true });
    } else if (this.platform.width() >= 640 && this.platform.width() < 1280 && this.state().screenType !== 'tablet') {
      this.setState({ screenType: 'tablet', isMobile: false, isTablet: true, isDesktop: false });
    } else if (this.platform.width() < 640 && this.state().screenType !== 'mobile') {
      this.setState({ screenType: 'mobile', isMobile: true, isTablet: false, isDesktop: false });
    }
  }
}
