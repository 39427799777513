<ion-app>
  @if (!disabledMenu()) {
    <div
      class="z-10 h-full shadow-100 mr-3 bg-white max-desktop:hidden"
      (mouseover)="isMenuHover.set(true)"
      (mouseleave)="isMenuHover.set(false)"
      [@menuWidth]="isMenuHover() ? 'expanded' : 'collapsed'"
    >
      <div class="flex h-full flex-col px-5 py-10">
        <div class="mb-5 h-[48px] hover:cursor-pointer" routerLink="/home">
          @if (isMenuHover()) {
            <img [@imageState]="isMenuHover() ? 'expanded' : 'collapsed'" ngSrc="assets/images/appines/logo-appines-title.png" class="w-full px-9" alt="appines-logo-title" height="38" width="227" priority />
          } @else {
            <img [@imageState]="isMenuHover() ? 'collapsed' : 'expanded'" ngSrc="assets/images/appines/logo-appines.jpg" alt="appines-logo" height="48" width="48" priority/>
          }
        </div>

        @for (item of menuItemsTop; track item.label) {
          <arc-menu-button [iconName]="item.iconName" [label]="item.label" [state]="isMenuHover() ? 'expanded' : 'collapsed'" (click)="item.action()" [selected]="item.rootUrl?.includes(currentRootUrl())" [badge]="item.badge ? item.badge() : undefined" [badgeVariant]="item.badgeVariant"></arc-menu-button>
        }

        <!--SEPARATOR TOP/BOTTOM-->
        <div class="mt-auto"></div>

        @for (item of menuItemsBottom; track item.label) {
          <arc-menu-button [iconName]="item.iconName" [label]="item.label" [state]="isMenuHover() ? 'expanded' : 'collapsed'" (click)="item.action()" [selected]="item.rootUrl?.includes(currentRootUrl())" [badge]="item.badge ? item.badge() : undefined" [badgeVariant]="item.badgeVariant"></arc-menu-button>
        }
      </div>
    </div>
  }

  <!-- Need ion-split-pane & ion-menu TO RESERVE first 88px-->
  <ion-split-pane contentId="main-content" when="(min-width: 1280px)" [disabled]="disabledMenu()" style="--side-max-width: 88px">
    <ion-menu contentId="main-content" type="overlay" menuId="sidebar" swipeGesture="false"> </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
