import { Routes } from '@angular/router';
import { authenticationRoutes } from './pages/authentication/authentication.routes';
import { tabsRoutes } from './tabs/tabs.routes';
import { formationRoutes } from './pages/formations/formation.routes';
import { profileRoutes } from './pages/profile/profile.routes';
import { practitionerRoutes } from './pages/practitioner/practitioner.routes';
import { structureRoutes } from './pages/structure/structure.routes';

export const routes: Routes = [
  ...tabsRoutes,
  ...authenticationRoutes,
  ...formationRoutes,
  ...profileRoutes,
  ...practitionerRoutes,
  ...structureRoutes,

  //todo: re-enable implementing register
  // {
  //   path: 'register',
  //   loadComponent: () => import('./pages/register/register.page').then((m) => m.RegisterPage),
  // },

  //! Must be at the end !
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
