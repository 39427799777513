import {Environment} from "./environment.model";

export const environment: Environment = {
  production: false,
  environmentName: 'integration',
  apiUrl: "https://v3-api-fwvzeseifq-ew.a.run.app",
  facebookAppId: "1156831505678365",
  sentryDSN: 'https://3c515ffe3969da71beff2da8b10f4986@o4507140416798720.ingest.de.sentry.io/4507140440850512',
  googleAuthClientId: "892110694405-248js0hbns716rukturul2qiaa1h0mmt.apps.googleusercontent.com",
  oneSignalAppId: "de0f94b2-25cb-457c-9731-de50fdce61e5",
  oneSignalSafariToken: "web.onesignal.auto.10bba952-d3e6-4be7-b269-bd5caae877a4",
  googlePlacesAPIKey: 'AIzaSyBRX3uKtHtpJgegELRxeT_DIRTTEWa9cGM'

};
