import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import {catchError, switchMap, throwError} from "rxjs";
import {environment} from "../../environments/environment";
import {inject} from "@angular/core";
import {AuthenticationService} from "../pages/authentication/authentication.service";
import {Router} from "@angular/router";

let isRefreshing = false;

/**
 * This interceptor refresh the accessToken with the refreshToken when AppInes backend send error code 498
 * If refreshToken is expired too, the session is clear, and user is redirected to the login page
 */
export const refreshTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error) => {
      if (
        error instanceof HttpErrorResponse &&
        req.url.includes(environment.apiUrl) &&
        error.status === 498
      ) {
        if (!isRefreshing) {
          isRefreshing = true;
        }

        if (authenticationService.hasAccessToken()) {
          // Refresh accessToken with refreshToken
          return authenticationService.refreshSessionToken().pipe(
            switchMap((res: {accessToken: string}) => {
              isRefreshing = false;

              // Assign the new Access Token
              const header:  { [p: string]: string | string[] } = { };
              header['Authorization'] = `Bearer ${res.accessToken}`;
              const clonedReq = req.clone({ setHeaders: header});

              // Rerun the request with the new Access Token
              return next(clonedReq);
            }),
            catchError(async (error) => {
              isRefreshing = false;

              if (error.status == '403' && error.error.code === 'invalid-refresh-token') {
                await authenticationService.clearTokens();
                // Reload current page
                window.location.href = router.url;
              }

              return error;
            })
          );
        }


      }
      return throwError(() => error);
    }));
};
