import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links';
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: 'root',
})
export class BranchIoService {
  constructor(private router: Router,
              private platform: Platform) {}

  public initDeepLinks() {
    if (this.platform.is('capacitor')) {
      // Need arrow function () => {} to bind this inside handleDeepLink in order to use router
      BranchDeepLinks.addListener('init', (event: BranchInitEvent) => this.handleDeepLink(event));

      BranchDeepLinks.addListener('initError', (error: any) => {
        console.error(error);
      });
    }
  }

  parseUrl(url: string): { path: string; queryParams: { [key: string]: string } } {
    const urlObj = new URL(url, window.location.origin); // Use a base URL to ensure correct parsing
    const path = urlObj.pathname;
    const queryParams: { [key: string]: string } = {};

    urlObj.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    return { path, queryParams };
  }

  handleDeepLink(event: BranchInitEvent) {
    // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
    // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
    if (event.referringParams['+clicked_branch_link'] && event.referringParams['$canonical_url']) {
      const { path, queryParams } = this.parseUrl(event.referringParams['$canonical_url']);
      this.router.navigate([path], { queryParams });
    }
  }
}
