import { Injectable } from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';
import {environment} from "../../environments/environment";
import {Platform} from "@ionic/angular";
import { OneSignal as OneSignalDesktop } from 'onesignal-ngx';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {

  constructor(private platform: Platform,
              private oneSignalDesktop: OneSignalDesktop) { }

  async initOneSignal() {
    if (this.platform.is('capacitor')) {
      // OneSignal.Debug.setLogLevel(6);

      OneSignal.initialize(environment.oneSignalAppId);

      OneSignal.Notifications.addEventListener('click', async (e) => {
        let clickData = await e.notification;
        console.log("Notification Clicked : " + clickData);
      });

      // TODO : move to special screen
      OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
        console.log("Notification permission granted " + success);
      });
    } else if (this.platform.is('desktop')) {

      // this.oneSignalDesktop.Debug.setLogLevel('info');

      await this.oneSignalDesktop.init({
        appId: environment.oneSignalAppId,
        safari_web_id: environment.oneSignalSafariToken,
        allowLocalhostAsSecureOrigin: (environment.environmentName === 'development'),
        autoResubscribe: true,
        promptOptions: {
          slidedown: {
            prompts: [{
              type: "push",
              autoPrompt: false,
              text: {
                acceptButton: "Autoriser",
                cancelButton: "Refuser",
                actionMessage: "Nous souhaitons vous notifier des nouvelles candidatures ou messages reçus."
              },
            }]
          }
        }
      });

      this.oneSignalDesktop.Notifications.addEventListener('click', async (e) => {
        let clickData = await e.notification;
        console.log("Desktop Notification Clicked : " + clickData);
      });


      // TODO : move to special screen
      // TODO : uncomment
      // this.oneSignalDesktop.Slidedown.promptPush({
      //   force: true,
      // }).then(() => {
      //   console.log("Notification permission granted");
      // }).catch(err => console.error(err));

    }
  }

  async login(userId: string) {
    console.info("Set OneSignal User");
    if (this.platform.is('capacitor')) {
      OneSignal.login(userId);
    } else if (this.platform.is('desktop')) {
      await this.oneSignalDesktop.login(userId);
    }
  }

  async logout() {
    if (this.platform.is('capacitor')) {
      OneSignal.logout();
    }else if (this.platform.is('desktop')) {
      await this.oneSignalDesktop.logout();
    }
  }
}
