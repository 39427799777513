import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthenticationService} from "../../pages/authentication/authentication.service";
import {inject} from "@angular/core";

export const AutoLoginGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> => {

  const authenticationService: AuthenticationService = inject(AuthenticationService);
  const router: Router = inject(Router);

  // verify that user is authenticated
  const result = await authenticationService.checkAuthentication();

  // Allow access to page, else redirect to login page
  return result ? router.createUrlTree(['/home']) : true;
};

