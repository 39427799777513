import { CanActivateFn } from '@angular/router';
import { PractitionerService } from '../../pages/practitioner/practitioner.service';
import { inject } from '@angular/core';

/*
* Because of the way Angular work, it is impossible to get a param of a route inside a service
* The workaround to that problem is to have a function in a guard that will call the service with the desired param instead
*/
export const PractitionerInfoGuard: CanActivateFn = (route) => {
  const practitionerService = inject(PractitionerService);
  practitionerService.setPractitionerInState(route.params?.['practitionerId']);
  return true;
};
