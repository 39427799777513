import { Component, input, InputSignal, OnInit, WritableSignal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ArcLinearProgressBar, ArcSpinner } from '@appines/archimed';

@Component({
  selector: 'app-custom-upload',
  templateUrl: './custom-upload.component.html',
  styleUrls: ['./custom-upload.component.scss'],
  standalone: true,
  imports: [IonicModule, ArcLinearProgressBar, ArcSpinner],
})
export class CustomUploadComponent implements OnInit {
  progress: InputSignal<WritableSignal<number>> = input.required<WritableSignal<number>>();
  pictureNumber = input<number | undefined>(undefined);
  constructor() {}

  ngOnInit() {}
}
