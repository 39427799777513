import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth/auth.guard';
import { MobileGuard } from 'src/app/guards/mobile-guard/mobile.guard';

export const profileRoutes: Routes = [
  {
    path: 'profile',
    canActivate: [AuthGuard],
    children: [
      // path: ''
      // is in tabs.routes.ts
      {
        path: 'my-profile',
        canActivate: [MobileGuard],
        children: [
          {
            path: '',
            loadComponent: () => import('./user-profile/user-profile.page').then((m) => m.UserProfilePage),
            title: 'Mon Profil',
          },
          {
            path: 'notifications',
            loadComponent: () => import('./handle-notifications/handle-notifications.page').then((m) => m.HandleNotificationsPage),
            title: 'Notifications',
          },
          {
            path: 'informations',
            loadComponent: () => import('./user-info/user-info.page').then((m) => m.UserInfoPage),
            title: 'Informations personnelles',
          },
          {
            path: 'connexion-security',
            children: [
              {
                path: '',
                loadComponent: () => import('./connexion-security/connexion-security.page').then((m) => m.ConnexionSecurityPage),
                title: 'Connexion & sécurité',
              },
              {
                path: 'change-email',
                loadComponent: () => import('./change-email/change-email.page').then((m) => m.ChangeEmailPage),
                title: 'Changer mon mail',
              },
              {
                path: 'change-password',
                loadComponent: () => import('./change-password/change-password.page').then((m) => m.ChangePasswordPage),
                title: 'Changer mon mot de passe',
              },
              {
                path: 'delete-account',
                loadComponent: () => import('./delete-account/delete-account.page').then((m) => m.DeleteAccountPage),
                title: 'Supprimer mon compte',
              },
            ],
          },
        ],
      },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
      },
    ],
  },
];
