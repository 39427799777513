import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ErrorService } from 'src/services/errorService/error.service';

export const missingPermissionInterceptor: HttpInterceptorFn = (req, next) => {
  const errorService = inject(ErrorService);
  return next(req).pipe(
    catchError((error) => {
      if (error.error.code === 'user-does-not-have-all-required-permissions' || error.error.code === 'structure-entity-permission-missing' || error.error.code === 'practitioner-entity-permission-missing') {
        errorService.handleError(error);
      }
      return throwError(() => error);
    })
  );
};
