import { Injectable } from '@angular/core';
import {FacebookLogin, FacebookLoginResponse} from "@capacitor-community/facebook-login";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FacebookLoginService {

  constructor() { }

  initialize() {
    FacebookLogin.initialize({ appId: environment.facebookAppId });
  }

  async login(): Promise<FacebookLoginResponse> {
    return await FacebookLogin.login({permissions: ['public_profile', 'email']});
  }

  async logout(): Promise<void> {
    return await FacebookLogin.logout();
  }
}
