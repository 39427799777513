import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonLabel, IonTabBar, IonTabButton, IonTabs, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { ArcIcon, ArcButton, IconNames } from '@appines/archimed';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
  standalone: true,
  imports: [IonContent, IonHeader, IonTitle, IonToolbar, CommonModule, FormsModule, IonTabs, IonTabBar, IonTabButton, IonLabel, ArcIcon, ArcButton],
})
export class TabsPage implements OnInit {
  tabItems: { label: string; iconName: IconNames; rootUrl: string }[] = [
    { label: 'Home', iconName: 'Home', rootUrl: 'home' },
    { label: 'Emplois', iconName: 'Briefcase', rootUrl: 'job' },
    { label: 'Formations', iconName: 'GraduationCap', rootUrl: 'formations' },
    { label: 'Profil', iconName: 'User', rootUrl: 'profile' },
  ];

  activeTab = signal('');

  constructor() {}

  ngOnInit() {}

  catchTabChange(ev: { tab: string }) {
    this.activeTab.set(ev.tab);
  }
}
